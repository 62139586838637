<template>
  <div>
    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/cc-home-hero.webp`,
        class: 'max-w-full w-[29.313rem] lg:h-[20.75rem]',
        dimensions: {
          width: 469,
          height: 332
        }
      }"
      :buttons="[
        {
          theme: 'primary',
          text: 'I WANT TO REFINANCE',
          url: verticalData.formPage
        },
        {
          theme: 'secondary',
          text: 'I WANT TO BUY A HOME',
          url: verticalData.formPage
        }
      ]"
      img-alt="Compare home loans"
      sub-title-class="!text-base md:text-lg"
    >
      <template #header>
        <strong class="font-extrabold">Compare Home Loans</strong>
      </template>

      <template #sub-header>
        Let our <strong>expert brokers</strong> take the hassle out of home loans.
        We'll find you the best loan from our panel of 50+ brokers and do all the hard work from application to settlement.
        See why thousands of Australians use Compare Club to keep their <strong>mortgage repayments low</strong>.
      </template>
    </molecules-hero-header>

    <molecules-home-company-comparison
      class="bg-white"
      :companies="PARTNER_ITEMS"
      :is-carousel="true"
      more-button-url="/home-loans/companies/"
      is-center
      theme="v2"
      image-lazy-load
    >
      <template #header>
        Compare home loans from <span class="font-extrabold">50+ lenders.</span>
      </template>
    </molecules-home-company-comparison>

    <MoleculesTrustpilot
      :layout="isMobile ? 'compact' : 'default'"
      class="py-8 border border-solid border-gray-350 min-h-[5.875rem] bg-white"
    />

    <AtomsMediaOutlets
      class="!py-10 md:px-12 md:p-0"
      image-lazy-load
      title-class="text-dark !pb-0"
      :is-carousel="true"
      :media-outlets="mediaOutlets"
      :theme="!isMobile ? 'compact' : 'default'"
    />

    <molecules-home-great-deal
      :cards="FEATURE_ITEMS"
      :col-count="4"
      class="bg-blue-5"
      wrapper-class="lg:!py-16"
      card-wrapper-max-width="w-wrapper-lg"
      card-class="py-[1.875rem] md:py-6"
      description-class="text-sm"
      image-lazy-load
    >
      <template #header>
        What <strong class="font-extrabold">home loan features</strong> are available to help <br class="md:block hidden" />you save money?
      </template>
    </molecules-home-great-deal>

    <div class="mx-auto p-6 pb-8 md:py-16 max-w-wrapper-md">
      <div>
        <div class="text-2xl font-bold pb-4">How much should I save as a deposit?</div>
        <div class="text-base">
          Financial institutions and lenders typically advise having a minimum of 20% of a property's value saved as a deposit. For instance, if you plan to purchase a home valued at $800,000, it is recommended to have an upfront savings of $160,000. This approach ensures an 80% loan-to-value ratio (LVR).<br><br>
          Our team can also help you with loans with a minimum 5% deposit.
        </div>
      </div>
      <hr class="my-10 max-w-wrapper-md bg-gray-400 border-b" />
      <div>
        <div class="text-2xl font-bold pb-4">What is LVR?</div>
        <div class="text-base">
          Your loan-to-value ratio is a calculation, usually expressed as a percent. You work it out by dividing the amount you owe on your home, by the value of your home.<br><br>
          For example:<br>
          Your home is valued at $1,000,000.<br>
          Your mortgate on your home is $800,000.<br><br>
          $800,000 <strong>÷</strong> $1,000,000 (x 100)<br><br>
          Your LVR = 80%<br><br>
          This is usually the threshold at which a lender will allow you to borrow without paying Lender's Mortgage Insurance (LMI). Most loan amounts that are above 80% of your property's value will require LMI.<br>
          LMI can cost up to 3% of your home loan amount. It's usually added on to your total loan amount and built into your repayments, but it can also be paid upfront if you have the funds.
        </div>
      </div>
    </div>

    <molecules-home-broker
      title="Meet our <strong>Brokers</strong>"
      :list="brokerList"
    />

    <div class="bg-dark py-10 md:py-16 px-4 md:px-0">
      <molecules-faq
        id="faq-apply"
        class="bg-white rounded-2xl max-w-2.5xl md:py-6 lg:py-6 md:px-9 lg:px-9 mx-auto"
        title="How do I apply for a home loan?"
        title-tag="h2"
        :list="FAQ_APPLY_ITEMS"
        :disable-initial-border="true"
        :disable-last-border="true"
      />
    </div>

    <div class="bg-gray-10 py-10 md:py-16 px-4 md:px-0">
      <div class="bg-white columns-1 md:columns-2 gap-0 max-w-wrapper-md mx-auto rounded-2xl overflow-hidden">
        <div class="min-h-[464px] pt-8 md:pt-10 pb-8 px-6 md:px-8">
          <div class="mb-8">
            <NuxtImg
              ref="home-loan-compare"
              src="https://asset.compareclub.com.au/edge/static/images/products/home-loans/cc-home-icon-mask.svg"
              alt="home-loan-compare"
              width="80"
              height="80"
            />
          </div>
          <p class="font-bold text-2xl md:text-2.5xl mb-6">Why should i compare home loans?</p>
          <p class="mb-4">Would you buy the first property you view without inspecting it or exploring other options? Of course not - your home is a huge investment, and so is your home loan.</p>
          <p>You’re smart enough to know that comparing home loans to find one that suits your financial situation makes sense.</p>
        </div>
        <div class="bg-blue-10 min-h-[464px] pt-6 md:pt-10 pb-8 md:pb-12 px-6 md:px-8">
          <p class="font-bold text-lg md:text-xl mb-6">When you compare home loans you have a better chance of finding one that has:</p>
          <ul>
            <li class="flex bg-white rounded-lg text-sm mb-4 py-2 px-3">
              <div class="h-4 w-4 content-center">
                <IconsCheck fill="#193560"/>
              </div>
              <span class="pl-2">Lower Fees</span>
            </li>
            <li class="flex bg-white rounded-lg text-sm mb-4 py-2 px-3">
              <div class="h-4 w-4 content-center">
                <IconsCheck fill="#193560"/>
              </div>
              <span class="pl-2">Lower interest rates</span>
            </li>
            <li class="flex bg-white rounded-lg text-sm mb-4 py-2 px-3">
              <div class="h-4 w-4 content-center">
                <IconsCheck fill="#193560"/>
              </div>
              <span class="pl-2">Easy-access offset facilities</span>
            </li>
            <li class="flex bg-white rounded-lg text-sm mb-4 py-2 px-3">
              <div class="h-4 w-4 content-center">
                <IconsCheck fill="#193560"/>
              </div>
              <span class="pl-2">Better customer service</span>
            </li>
            <li class="flex bg-white rounded-lg text-sm py-2 px-3">
              <div class="h-4 w-4 content-center">
                <IconsCheck fill="#193560"/>
              </div>
              <span class="pl-2">Banking facilities that suit you best</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <molecules-home-great-deal
      :cards="REPAYMENTS"
      :col-count="isMobile ? 1 : 2"
      :card-wrapper-max-width="isMobile ? 'w-wrapper-[382px]' : 'w-wrapper-md'"
      wrapper-class="lg:!py-16"
      class="bg-white"
      card-class="p-6 pb-8 shadow-none border-solid border-1 border-gray-400"
      title-class="!text-left !text-2xl !mb-4"
      description-class="!text-left"
      image-lazy-load
    >
      <template #header>
        How our
        <img
          class="inline-block h-9 md:h-10 md:mr-3"
          :src="expertEaseImg"
          :width="!isMobile ? '230' : '207'"
          height="40"
          alt="Expert ease"
          loading="lazy"
        />

        <strong class="font-extrabold">helps</strong> cut your<br class="md:block hidden" /> mortgage repayments
      </template>

      <template #sub-header>
        <div class="text-base md:text-lg">
          Our <strong>experts</strong> start by comparing interest rates from over <strong>40 lenders in Australia.</strong> In a matter of minutes, we can show how much you could save when you apply for a mortgage with us.
        </div>
      </template>
    </molecules-home-great-deal>

    <div class="bg-gray-10">
      <div
        class="m-auto py-10 md:py-16 px-4 lg:px-0 w-wrapper-[382px] md:max-w-wrapper-xl"
      >
        <p
          class="md:!text-4.5xl text-2.5xl lg:text-center mb-4 md:mb-2 lg:mb-6"
        >
          Home Loan <strong class="font-extrabold">Calculators</strong>
        </p>
        <p class="text-lg lg:text-center mb-6 lg:mb-10 max-w-wrapper-md mx-auto">
          Explore our home loan calculators for a practical approach to budgeting. These calculators can help you:
        </p>

        <div class="md:mx-4 md:px-4 pb-4 overflow-x-scroll xl:overflow-x-hidden">
          <div class="grid gap-8 mx-auto grid-cols-1 md:grid-cols-3 md:w-wrapper-lg">
            <atoms-card
              v-for="(card, cardKey) in CALCULATOR_ITEMS"
              :key="cardKey + '-calculator'"
              shadow="bordered"
              class="flex flex-row md:flex-col text-left min-w-[13rem] shadow-none border-solid border-1 border-gray-400"
              size="md"
            >
              <div class="flex-none px-2 md:px-0">
                <NuxtImg
                  v-if="card.icon"
                  :src="card.icon"
                  :alt="card.title"
                  :width="card.iconDimensions && card.iconDimensions.width"
                  :height="card.iconDimensions && card.iconDimensions.height"
                  :class="card.iconClass"
                  loading="eager"
                  class="mx-auto w-20 h-20"
                />
              </div>
              <div class="grow text-left md:text-center">
                <!-- eslint-disable vue/no-v-html -->
                <p
                  v-if="card.title"
                  class="text-lg font-bold mb-3 md:mt-6"
                  v-html="card.title"
                >
                </p>

                <p
                  v-if="card.description"
                  class="text-base flex-1"
                  :class="{
                    'mb-8': card.url && !isMobile,
                  }"
                  v-html="card.description"
                >
                </p>
                <atoms-button
                  v-if="card.url"
                  class="mx-auto text-sm px-0 md:px-4"
                  :size="isMobile ? 'small' : 'large'"
                  theme="tertiary"
                  :to="card?.url"
                  :class="[
                    {
                      'mt-6': !card.description
                    }
                  ]"
                >
                  {{ buttonConfig?.label || 'LEARN MORE' }}
                </atoms-button>
              </div>
            </atoms-card>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white px-4 lg:px-0 py-10 md:py-20">
      <div class="max-w-wrapper-md mx-auto">
        <p class="text-2xl md:text-3.5xl text-center leading-10 md:leading-[3rem]">
          How we’ve <strong class="font-extrabold">helped Australians</strong> like you.
        </p>

        <MoleculesTrustpilotReviews
          class="mt-8"
          custom-class="text-base md:text-2xl/[1.625rem]"
          :tags="vertical.replace(/\s/g, '-')"
        />
      </div>
    </div>

    <div class="bg-gray-10 py-10 md:py-16 px-4 md:px-0">
      <AtomsAuthorBlock
        :name="AUTHOR_ITEM.name"
        :description="AUTHOR_ITEM.description"
        :vertical="AUTHOR_ITEM.vertical"
        :image="AUTHOR_ITEM.image"
        :tips="AUTHOR_ITEM.tips"
        theme="expanded"
      />
    </div>

    <atoms-site-links
      class="bg-white lg:!py-16"
      :items="SITE_LINKS"
      :in-guides="true"
    >
      <template #header>
        Learn more about <strong>home loans</strong>
      </template>
    </atoms-site-links>

    <div class="bg-gray-10">
      <molecules-faq
        id="glossary"
        class="py-10 lg:!py-16 md:px-9 lg:px-9 max-w-2.5xl mx-auto"
        title="A glossary of home loan terms"
        :list="GLOSSARY_ITEMS"
        :multiple-collapse="false"
        hide-schema
      >
        <div class="text-sm">
          Here’s some of the most common jargon you’ll need to be familiar with when shopping for a home loan:
        </div>
      </molecules-faq>
    </div>

    <molecules-faq
      id="faq"
      class="bg-white rounded-2xl max-w-2.5xl py-10 lg:!py-16 md:px-9 lg:px-9 mx-auto"
      title="Home Loans FAQs"
      title-tag="h2"
      title-class="!text-2.5xl"
      :list="FAQ_ITEMS"
    >
      <div class="text-lg">
        Common questions about comparing home loans. Here are some of the most frequently asked questions we receive about home loans:
      </div>
    </molecules-faq>

    <molecules-home-guide-tiles
      bg-class="bg-gray-10 lg:!py-16"
      theme="v2"
      image-lazy-load
      sub-header-class="!text-base md:text-lg"
      :guides="GUIDES_ITEMS"
      :action-button-url="VERTICALS[vertical].guidesUrl"
    >
      <template #header>
        <strong class="font-extrabold">Got home loan questions?</strong>
      </template>

      <template #sub-header>
        Want to know more? Our in-depth expert guides have the answers
      </template>
    </molecules-home-guide-tiles>

    <molecules-home-footer-info class="bg-white" theme="gray-boxed">
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          Here are a few essential bits of information about us. Your home loan is all about you, but this should give you confidence you're dealing with professionals. It's also legally important for us to tell you this.
        </p>
        <p>
          Compare Club is an Authorised Corporate Credit Representative of Alternative Media Pty Ltd ABN 17 149 089 716 (Australian Credit Licence Number 486326).
        </p>
        <p>
          Everything on this page only provides general information about home loans. It's been prepared without taking into account your objectives, financial situation or needs. We recommend that you consider whether it is appropriate for your circumstances. Your full financial situation will need to be reviewed prior to acceptance of any offer or product. It does not constitute legal, tax or financial advice - always seek professional advice in relation to your individual circumstances. Products and rates quoted are subject to lenders terms and conditions, fees and charges and eligibility criteria apply.
        </p>
        <p>
          * The Interest Rate quoted is for a variable rate owner occupied loan with principal and interest payments, loan to value ratio below 60% and minimum loan amount of $250,000. Interest Rates are correct as at 13/10/2022 and subject to change at any time. The comparison rate is based on a loan amount of $150,000, over a 25 year term. Warning: This comparison rate is true only for the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. Terms, conditions, fees and charges apply and your full financial situation would need to be reviewed prior to acceptance of any offer or product.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  PARTNER_ITEMS,
  REPAYMENTS,
  FAQ_APPLY_ITEMS,
  FEATURE_ITEMS,
  CALCULATOR_ITEMS,
  SITE_LINKS,
  GLOSSARY_ITEMS,
  FAQ_ITEMS,
  GUIDES_ITEMS,
  AUTHOR_ITEM
} from '~/constants/pages/home-loans'

const trustpilotStore = useTrustpilotStore()

const { devices } = deviceChecker()

const vertical = VERTICAL.HOME_LOANS
const verticalData = VERTICALS[vertical]
const $route = useRoute()

defineOptions({
  name: 'HomeLoans'
})

const brokerList = computed(() => {
  return [
    {
      icon: `${APP_ASSET_BASEURL}edge/static/images/products/agents/julia.svg`,
      iconDimensions: {
        width: 94,
        height: 94
      },
      name: 'Alison',
      description: 'Alison specialises in reverse mortgage applications, wielding finesse and expertise like a seasoned pro. With a passion for problem-solving and an impeccable eye for detail, she approaches even the most complicated scenarios with unwavering enthusiasm and determination. To Alison, each application is not just a set of documents but a puzzle'
    },
    {
      icon: `${APP_ASSET_BASEURL}edge/static/images/products/agents/monique.svg`,
      iconDimensions: {
        width: 94,
        height: 94
      },
      name: 'Niamh',
      description: 'Loves to turn first-time buyers\' dreams into reality. With a blend of empathy, expertise, and dedication, she relishes the opportunity to guide them through the journey of purchasing their first home. Trust her to make your first home-buying experience as seamless and fulfilling as possible.'
    },
    {
      icon: `${APP_ASSET_BASEURL}edge/static/images/products/agents/camie.svg`,
      iconDimensions: {
        width: 94,
        height: 94
      },
      name: 'Sophie',
      description: 'Sophie, has a passion for refinancing that goes beyond simply securing a new loan. With a keen eye for detail and a commitment to client satisfaction, she revels in the opportunity to unveil hidden savings and tailor-made solutions for each individual.'
    },
    {
      icon: `${APP_ASSET_BASEURL}edge/static/images/products/agents/chris1.svg`,
      iconDimensions: {
        width: 94,
        height: 94
      },
      name: 'Grant',
      description: 'Grant is a mortgage specialist who thrives on non-conforming applications. Grant approaches complicated scenarios with enthusiasm and determination. Whether it\'s navigating complex financial histories, unconventional property types, or challenging credit situations, Grant is dedicated to finding innovative solutions tailored to each client\'s needs.'
    },
    {
      icon: `${APP_ASSET_BASEURL}edge/static/images/products/agents/chris2.svg`,
      iconDimensions: {
        width: 94,
        height: 94
      },
      name: 'Rick',
      description: 'Meet Rick, a multilingual mortgage expert with a passion for complexity and a love for languages. Rick effortlessly navigates the diverse landscape of mortgage applications with finesse and precision. His knack for unraveling intricate financial puzzles has earned him a reputation for excellence in the industry. With Rick, you get a dynamic individual who thrives on challenges.'
    }
  ]
})

const isMobile = computed(() => {
  return devices.isMobile.value ? true : false
})

const mediaOutlets = computed(() => {
  return [
    {
      url: `${APP_ASSET_BASEURL}images/logos/lifehacker.svg`,
      width: 75,
      height: 32
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/7-news.svg`,
      width: 112.25,
      height: 32
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/yahoo.svg`,
      width: 88.1,
      height: 32
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/kidspot.svg`,
      width: 122.8,
      height: 32
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/news.svg`,
      width: 158.25,
      height: 32
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/buzzfeed.svg`,
      width: 145.06,
      height: 24
    }
  ]
})

generateMeta({
  title: 'Compare Home Loan & Mortgage Rates | Compare Club',
  ogTitle: 'Compare Home Loan & Mortgage Rates | Compare Club',

  description: 'Want a better home loan? Compare Club makes comparing home loans easy. Click here to get help from our experts',
  ogDescription: 'Want a better home loan? Compare Club makes comparing home loans easy. Click here to get help from our experts',

  ogUrl: 'https://compareclub.com.au/home-loans/',
  ogImage: '',
  ogType: 'product'
}, pageIsFunnel($route))

useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Home loan comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/home-loans/',
    description: 'Our brokers help keep the cost of your monthly mortgage low. Our experts help hundreds of homeowners, new buyers, and investors find better value home loans each week.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: trustpilotStore.trustpilotData?.trustScore,
      bestRating: '5',
      worstRating: '1',
      ratingCount: trustpilotStore.trustpilotData?.numberOfReviews.total
    }
  }
})
</script>
